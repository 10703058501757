import { InlineMarkdownRender } from '@/src/modules/markdown/components/InlineMarkdownRender';
import { useChecklistLinkTrigger } from '@/src/modules/onboarding/hooks/useChecklistLinkTrigger';
import { CheckListItem } from '@/src/modules/onboarding/onboarding.types';
import { Checkbox } from '@/src/modules/ui/components/Checkbox';
import { Flex } from '@/src/modules/ui/components/Flex';
import { ListItem } from '@/src/modules/ui/components/List/List';
import { mediaHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import React from 'react';
import styled from 'styled-components';

const StyledListItemContent = styled(Flex).attrs((p) => ({
  gap: 12,
  alignItems: 'center',
  ...p,
}))`
  padding: 0 0.125rem;
  min-height: 40px;

  &:not(:has(img:not([data-no-mobile]))) {
    padding: 0.4rem 0.125rem;
  }
`;

const StyledListItem = styled(ListItem)`
  --strike: 0;

  background: none;
  border: none;
  padding: 0;

  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${cssVar['color-text-tertiary']};
  transition: 0.2s ease-in-out;
  width: fit-content;

  ${StyledListItemContent} {
    p {
      display: inline;
      position: relative;

      background: linear-gradient(90deg, transparent, currentColor 0) no-repeat left center /
        calc(var(--strike) * 100%) 0.1em;
      background-position-x: left;
      background-position-y: center;

      transition: background-size 0.4s ease;

      img {
        z-index: -1;
        position: relative;
      }
    }
  }

  ${mediaHover} {
    &:hover:not([data-state='completed']) {
      ${StyledListItemContent} {
        > :not(:first-child) {
          filter: brightness(1.1) saturate(1.1);
        }
      }
    }
  }

  &[data-type='task'] {
    cursor: pointer;
  }

  &[data-disabled='true'] {
    pointer-events: none;

    a {
      pointer-events: visible;
    }
  }

  &[data-state='completed'] {
    --strike: 1;
  }
`;

interface ChecklistItemProps {
  item: CheckListItem;
}

export const ChecklistItem: React.FC<ChecklistItemProps> = ({ item }) => {
  const handleLinkTrigger = useChecklistLinkTrigger();

  return (
    <StyledListItem
      onClick={() => handleLinkTrigger(item)}
      data-state={item.completed ? 'completed' : 'default'}
      data-disabled={item.completed}
      data-type={item.type}
    >
      <StyledListItemContent>
        <Checkbox checked={item.completed} disabled />
        <InlineMarkdownRender
          markdown={item.content}
          onLinkOpen={(url) => handleLinkTrigger(item, url)}
        />
      </StyledListItemContent>
    </StyledListItem>
  );
};
