import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { cssVar } from '../../ui/theme/variables';
import { parseInlineMarkdown } from '../markdown.utils';

type InlineMarkdownRenderProps = {
  markdown: string;
  onLinkOpen?: (url: string) => void;
};

const InlineMarkdownSpan = styled.span`
  display: inline;
  white-space: normal;
  word-wrap: break-word;

  a {
    text-decoration: underline;
  }

  code {
    display: inline;
    background-color: ${cssVar['color-bg-secondary']};
    color: ${cssVar['color-text-tertiary']};
    padding: 0.2em 0.4em;
    border-radius: 4px;
    font-family: 'Courier Prime', monospace;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    max-width: unset;
    // avoid pushing text off middle
    margin-top: 0.05em;
    transform: translateY(-0.05em);
  }

  u {
    text-decoration: underline;
    text-underline-offset: 0.2em;
  }

  ${mediaMobile} {
    [data-no-mobile] {
      display: none;
    }
  }
`;

export const InlineMarkdownRender = forwardRef<
  HTMLSpanElement,
  InlineMarkdownRenderProps & React.HTMLAttributes<HTMLSpanElement>
>(({ markdown, onLinkOpen, ...props }, ref) => {
  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (onLinkOpen && event.target instanceof HTMLAnchorElement) {
      event.preventDefault();
      onLinkOpen(event.target.getAttribute('href') || event.target.href);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (onLinkOpen && event.key === 'Enter' && event.target instanceof HTMLAnchorElement) {
      event.preventDefault();
      onLinkOpen(event.target.getAttribute('href') || event.target.href);
    }
  };

  return (
    <InlineMarkdownSpan
      {...props}
      ref={ref}
      dangerouslySetInnerHTML={{ __html: parseInlineMarkdown(markdown) }}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    />
  );
});

InlineMarkdownRender.displayName = 'InlineMarkdownRender';
