import {
  FeatureFlag,
  useIsFeatureFlagEnabled,
} from '@/src/modules/feature-flags/feature-flags.store';
import { Flex } from '@/src/modules/ui/components/Flex';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled, { css } from 'styled-components';

export const HomeSectionTitle = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: max-content;
  color: ${cssVar['color-text-primary']};
`;

export const HomeSection = styled(Flex).attrs({
  direction: 'column',
})`
  gap: 1.25rem;
`;

export const ResourceItemsContainer = styled(Flex).attrs<{ isNewPreviewCard?: boolean }>(
  (props) => {
    const isNewPreviewCard = useIsFeatureFlagEnabled(FeatureFlag.RESOURCE_CARDS_V2);
    return {
      gap: 'elementsContainer',
      isNewPreviewCard,
      ...props,
    };
  },
)`
  height: ${(p) => (p.isNewPreviewCard ? 'calc(192px + 4px)' : 'calc(164px + 4px)')};
  padding-bottom: 4px;
  ${(p) =>
    p.isNewPreviewCard
      ? css`
          & > * {
            width: 168px;
            height: 192px;
          }
        `
      : css`
          & > * {
            width: 164px;
            height: 164px;
          }
        `}
`;
