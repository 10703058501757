import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { connectionQueryPredicates } from '@/src/modules/connections/queries/connectionQueryPredicates';
import { rootQueryPredicates } from '@/src/modules/resource-roots/queries/rootQueryPredicates';
import { useWoody } from '@/src/services/woody/woody';
import { components } from '@fabric/woody-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type MutationVariables = {
  integrationId: string;
  status: components['schemas']['IntegrationUpdateRequest']['status'];
};

export const useMutationUpdateConnection = () => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();
  const { track } = useAnalytics();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ integrationId, status }: MutationVariables) => {
      if (!isLoggedIn) {
        return;
      }

      await client.v2(
        {
          endpoint: '/v2/integrations/{integrationId}',
          params: {
            integrationId: integrationId,
          },
        },
        {
          method: 'patch',
          body: {
            status,
          },
        },
      );
    },
    onSuccess: (_, { integrationId, status }) => {
      queryClient.invalidateQueries({
        predicate: (q) =>
          connectionQueryPredicates.integrationByExternalIdAll(q) ||
          rootQueryPredicates.resourceRootAll(q),
      });

      track(
        status === 'ACTIVE' ? AnalyticsEvents.ResumedConnection : AnalyticsEvents.PausedConnection,
        { integrationId: integrationId },
      );
    },
  });
};
