import { useQueryUserPreferences } from '@/src/modules/user/queries/useQueryUserPreferences';

const useIsChecklistHidden = () => {
  const { data: preferences, isLoading } = useQueryUserPreferences();

  const isChecklistHidden = !preferences?.frontend.onboardingChecklistShow || isLoading;

  return isChecklistHidden;
};

export { useIsChecklistHidden };
