import NextLink from 'next/link';
import styled, { css } from 'styled-components';

interface LinkProps {
  enableTouchDefaults?: boolean;
}

/**
 * by default, this link
 * * disables default context menu (long press) on touch devices
 * * disables text selection
 * * disables drag and drop
 */
export const LinkTouch = styled(NextLink).attrs<LinkProps>((props) => {
  return {
    ...props,
    draggable: props.enableTouchDefaults ? true : false,
  };
})`
  ${(p) =>
    !p.enableTouchDefaults &&
    css`
      -webkit-touch-callout: none !important; // also disables text select
      -webkit-user-select: none !important;
      user-select: none !important;
    `}
`;
