import Skeleton from '@/src/components/Skeleton/Skeleton';
import { Flex } from '@/src/modules/ui/components/Flex';
import React from 'react';
import styled from 'styled-components';

const SkeletonItem = styled(Flex).attrs((p) => ({
  gap: 'sectionsShort',
  alignItems: 'center',
  ...p,
}))`
  height: 40px;
  padding: 7px 2px;
`;

const SkeletonList = styled(Flex).attrs((p) => ({
  direction: 'column',
  ...p,
}))`
  width: 100%;
  margin-top: 20px;
  gap: 2px;
`;

const skeletonItems = [300, 280, 350, 190];

export const ChecklistSkeleton: React.FC = () => {
  return (
    <SkeletonList>
      {skeletonItems.map((item) => (
        <SkeletonItem key={item}>
          <Skeleton width={16} height={16} borderRadius={4} />
          <Skeleton width={item} height={18} borderRadius={8} />
        </SkeletonItem>
      ))}
    </SkeletonList>
  );
};
