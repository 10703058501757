import ChevronRightThinIcon from '@/public/images/icons/ChevronRightThin.svg';
import RoundedBookmarkIcon from '@/public/images/icons/RoundedBookmark.svg';
import SimpleSpace from '@/src/components/SimpleSpace/SimpleSpace';
import { DashboardContentSection } from '@/src/core/shared/Dashboard/DashboardContent';
import { useResponsive } from '@/src/hooks/responsive';
import {
  HomeSection,
  HomeSectionTitle,
  ResourceItemsContainer,
} from '@/src/modules/home/components/HomeComponents';
import { ResourcePreviewCompactCard } from '@/src/modules/resources/components/ResourcePreviewCompactCard';
import { useQueryResourceRootSpaceSystemList } from '@/src/modules/spaces/queries/useQueryResourceRootSpaceSystemList';
import { LinkTouch } from '@/src/modules/ui/components/Link/LinkTouch';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

const SpacesEmptyCard = styled.div`
  width: 164px;
  height: 164px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: ${cssVar['color-text-placeholder']};
  background: ${cssVar['color-bg-tertiary']};
  border-radius: 16px;
  padding: 20px;

  > p {
    max-width: 200px;
    text-align: center;
  }
`;

export const HomeRecentSpacesSection = () => {
  const { isMobileView } = useResponsive();

  const { spaceRoots, isLoading } = useQueryResourceRootSpaceSystemList();

  return (
    <HomeSection>
      <DashboardContentSection>
        <HomeSectionTitle as={LinkTouch} href="/spaces">
          Recent spaces <ChevronRightThinIcon style={{ height: 12 }} />
        </HomeSectionTitle>
      </DashboardContentSection>
      <ScrollArea orientation="horizontal" scrollbarVariant="none">
        <DashboardContentSection>
          <ResourceItemsContainer>
            <ResourcePreviewCompactCard
              variant="purple-pink"
              title="Inbox"
              icon={<RoundedBookmarkIcon style={{ width: 19, height: 'auto' }} />}
              as={LinkTouch}
              href="/saved"
            />
            {spaceRoots
              ?.slice(0, isMobileView ? 5 : 6)
              .map((spaceRoot) => (
                <SimpleSpace
                  enableDroppableTargetForResources
                  forcedCompact
                  key={spaceRoot.id}
                  rootSpace={spaceRoot}
                  resourceStackScaleMobile={0.27}
                />
              ))}
            {spaceRoots?.length === 0 && !isLoading && !isMobileView && (
              <SpacesEmptyCard>
                <p>Your recent spaces will appear here</p>
              </SpacesEmptyCard>
            )}
          </ResourceItemsContainer>
        </DashboardContentSection>
      </ScrollArea>
    </HomeSection>
  );
};
