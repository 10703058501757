import { useResponsive } from '@/src/hooks/responsive';
import { Button } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import { P } from '@/src/modules/ui/components/Typography';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { motion, MotionValue } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Title = styled(P).attrs((p) => ({
  size: 'lg',
  weight: 700,
  ...p,
}))`
  line-height: 1.188rem;
`;

const Percentage = styled(P).attrs((p) => ({
  size: 'lg',
  weight: 700,
  color: 'app-primary',
  ...p,
}))`
  line-height: 1.188rem;
`;

const DontShowButton = styled(Button).attrs((p) => ({
  variant: 'outline-secondary',
  size: 'xss',
  ...p,
}))`
  border-color: ${cssVar['color-border-primary']};
  border-radius: 4px;
  font-weight: 400;
  padding: 0.28rem 0.55rem;

  ${mediaMobile} {
    align-self: start;
    justify-self: flex-end;
    margin-left: auto;
  }
`;

interface ChecklistHeaderProps {
  percentage: number | MotionValue<number>;
  onClickDontShowAgain: () => void;
}

export const ChecklistHeader: React.FC<ChecklistHeaderProps> = ({
  percentage,
  onClickDontShowAgain,
}) => {
  const { isMobileView } = useResponsive();

  return (
    <Flex gap={24} alignItems="center">
      <Flex
        gap="element"
        alignItems={isMobileView ? 'flex-start' : 'center'}
        direction={isMobileView ? 'column' : 'row'}
      >
        <Title>Get started checklist</Title>
        <Flex gap="text" alignItems="center">
          <Percentage>
            – <motion.span>{percentage}</motion.span>% complete
          </Percentage>
        </Flex>
      </Flex>
      <DontShowButton onClick={onClickDontShowAgain}>Don&lsquo;t show again</DontShowButton>
    </Flex>
  );
};
