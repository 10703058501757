import { useRunOnce } from '@/src/hooks/useRunOnce';
import { useQueryResourceRootIntegrationList } from '@/src/modules/connections/queries/useQueryResourceRootIntegrationList';
import { useMutationCheckListItemState } from '@/src/modules/onboarding/mutations/useMutationCheckListItemState';
import { OnboardingCheckListActionId } from '@/src/modules/onboarding/onboarding.config';
import { UserPreferences } from '@/src/modules/user/user.types';

/**
 * Since the integrations creation in the FE are all over the place still this hook
 * will use the already fetched integration list to auto complete the connection item if the user has at least one connection.
 * @TODO this should be moved into an action-based tick when FE unifies the integrations creation process.
 */
export const useAutoCheckItems = (preferences: UserPreferences | undefined, disabled = false) => {
  const { mutate: mutateCheckListItemState } = useMutationCheckListItemState();

  const { integrationRoots, isLoading: integrationRootsLoading } =
    useQueryResourceRootIntegrationList();

  const integrationRootsLength = integrationRoots.length;

  useRunOnce(
    () => {
      mutateCheckListItemState({
        actionId: OnboardingCheckListActionId.ADD_FIRST_CONNECTION,
        state: true,
      });
    },
    integrationRootsLength > 0 && !disabled && !integrationRootsLoading,
  );
};
