import { PHAuthorizationStatus } from '@/mobile/ScreenshotConnection';

import UpgradePromptModal from '@/src/components/UpgradePromptModal/UpgradePromptModal';
import { useAuthUser } from '@/src/hooks/auth';
import { useRemoveConnection } from '@/src/hooks/connections/useConnection';
import { isInMobile } from '@/src/hooks/mobile';
import { useBoolState } from '@/src/hooks/useBooleanState';
import { OpenObjectStateOnSet, useObjectState } from '@/src/hooks/useObjectState';
import { pick } from '@/src/lib/store';
import {
  ApiIntegration,
  ApiIntegrationWithViewConfig,
} from '@/src/modules/connections/connections.types';
import { useMutationUpdateConnection } from '@/src/modules/connections/mutations/useMutationUpdateConnection';
import { IconConnectionPause, IconConnectionResume, IconTrash } from '@/src/modules/icons';
import { DropdownMenu } from '@/src/modules/ui/components/DropdownMenu/DropdownMenu';
import { confirm } from '@/src/store/alerts';
import useScreenshotsStore from '@/src/store/screenshots';
import { isSubscribedPlan } from '@/src/types/pricing';
import React, { PropsWithChildren } from 'react';
import { shallow } from 'zustand/shallow';

type ConnectionsListActionsContextProps = {
  setConnectionToRemoveState: (object: ApiIntegration | null) => void;
  screenshotConnection: {
    toggleScreenshotConnection: () => void;
    isRegistered: boolean;
    toggleEnabled: boolean;
  };
};

const ConnectionsListActionsContext = React.createContext<ConnectionsListActionsContextProps>(
  {} as ConnectionsListActionsContextProps,
);

export const ConnectionsListActionsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useAuthUser();
  const removeConnection = useRemoveConnection();

  const { mutate: mutateUpdateConnection } = useMutationUpdateConnection();

  const {
    value: upgradePromptActive,
    handleTrue: openUpgradePrompt,
    handleFalse: closeUpgradePrompt,
  } = useBoolState(false);

  /**
   * screenshot connection specific code
   */

  const { imageAccess, isRegistered, setIsRegistered } = useScreenshotsStore(
    (state) => pick(state, ['imageAccess', 'isRegistered', 'setIsRegistered']),
    shallow,
  );

  const canToggleScreenshotConnection =
    !isInMobile() ||
    (imageAccess.status !== PHAuthorizationStatus.Authorized &&
      imageAccess.status !== PHAuthorizationStatus.Limited);

  const toggleScreenshotConnection = React.useCallback(async () => {
    if (!isSubscribedPlan(user?.subscription.tier) && !isRegistered) {
      openUpgradePrompt();
      return;
    }

    if (
      !isInMobile() ||
      (imageAccess.status !== PHAuthorizationStatus.Authorized &&
        imageAccess.status !== PHAuthorizationStatus.Limited)
    )
      return;

    mutateUpdateConnection({
      integrationId: imageAccess.uniqueId,
      status: isRegistered ? 'INACTIVE' : 'ACTIVE',
    });

    setIsRegistered(!isRegistered);
  }, [
    user?.subscription.tier,
    isRegistered,
    imageAccess,
    mutateUpdateConnection,
    setIsRegistered,
    openUpgradePrompt,
  ]);

  /**
   * regular connections
   */

  const onSetConnectionToRemove: OpenObjectStateOnSet<ApiIntegration> = React.useCallback(
    async (state) => {
      if (!state.value) {
        return;
      }
      const confirmed = await confirm({
        title: 'Remove connection - are you sure?',
        content:
          'This will remove the connection and its contents from Fabric. You can reconnect later.',
        confirmLabel: 'Yes, remove',
        cancelLabel: 'Cancel',
        confirmButtonProps: {
          variant: 'danger',
        },
      });

      if (confirmed) {
        removeConnection(state.value);
      }
      state.clear();
    },
    [removeConnection],
  );

  const { set: setConnectionToRemoveState } = useObjectState<ApiIntegration>(null, {
    onSet: onSetConnectionToRemove,
  });

  const contextValue: ConnectionsListActionsContextProps = React.useMemo(
    () => ({
      setConnectionToRemoveState,
      toggleScreenshotConnection,
      screenshotConnection: {
        toggleScreenshotConnection,
        isRegistered,
        toggleEnabled: canToggleScreenshotConnection,
      },
    }),
    [
      setConnectionToRemoveState,
      toggleScreenshotConnection,
      isRegistered,
      canToggleScreenshotConnection,
    ],
  );

  return (
    <ConnectionsListActionsContext.Provider value={contextValue}>
      {/** upgrade prompt */}
      {upgradePromptActive && (
        <UpgradePromptModal
          title="Upgrade to add connections"
          subtitle="Automatically sync from all your apps, clouds and drives."
          onClose={closeUpgradePrompt}
        />
      )}
      {children}
    </ConnectionsListActionsContext.Provider>
  );
};

export const ConnectionsListDropdownAction: React.FC<{
  integration: ApiIntegrationWithViewConfig;
}> = ({ integration }) => {
  const { setConnectionToRemoveState, screenshotConnection } = React.useContext(
    ConnectionsListActionsContext,
  );

  if (integration.type === 'IOS_DEVICE' && !screenshotConnection.toggleEnabled) {
    return null;
  }

  return (
    <DropdownMenu
      renderTriggerElement={({ onOpenChange }) => (
        <DropdownMenu.TriggerButtonThreeDotsSlim onOpenChange={onOpenChange} />
      )}
    >
      {/** screenshot sync specific */}
      {(integration.type === 'IOS_DEVICE' || integration.type === 'ANDROID_DEVICE') && (
        <DropdownMenu.Group>
          <DropdownMenu.Item
            onClick={screenshotConnection.toggleScreenshotConnection}
            data-testid={`screenshot-toggle-button`}
            iconElement={
              screenshotConnection.isRegistered ? <IconConnectionPause /> : <IconConnectionResume />
            }
          >
            {screenshotConnection.isRegistered ? 'Pause' : 'Resume'} connection
          </DropdownMenu.Item>
        </DropdownMenu.Group>
      )}
      <DropdownMenu.Group>
        <DropdownMenu.Item
          onClick={() => setConnectionToRemoveState(integration)}
          data-testid={`delete-connection-button-${integration.id}`}
          variant="danger"
          iconElement={<IconTrash />}
        >
          Remove
        </DropdownMenu.Item>
      </DropdownMenu.Group>
    </DropdownMenu>
  );
};
