import { CheckListItem } from '@/src/modules/onboarding/onboarding.types';
import { calculatePercentage } from '@/src/modules/onboarding/utils/calculatePercentage';
import { animate, useMotionValue, useTransform } from 'framer-motion';
import { useEffect } from 'react';

export const useChecklistCompletion = (items: CheckListItem[] | undefined) => {
  const rawPercentage = useMotionValue(0);
  const percentage = useTransform(rawPercentage, (value) => Math.round(value));

  useEffect(() => {
    if (!items) return;

    const percentageValue = calculatePercentage(items);
    const controls = animate(rawPercentage, percentageValue, {
      duration: 0.5,
      delay: 0.2,
      ease: 'easeInOut',
    });

    return () => controls.stop();
  }, [items, rawPercentage]);

  return { percentage };
};
