import { useMutationCheckListItemState } from '@/src/modules/onboarding/mutations/useMutationCheckListItemState';
import { CheckListItem } from '@/src/modules/onboarding/onboarding.types';
import {
  isCheckListItemLink,
  isCheckListItemTask,
} from '@/src/modules/onboarding/onboarding.utils';
import { useRouter } from 'next/router';

export const useChecklistLinkTrigger = () => {
  const router = useRouter();
  const { mutate: mutateCheckListItemState } = useMutationCheckListItemState();

  const handleLinkTrigger = (item: CheckListItem, fallbackUrl?: string) => {
    const url =
      fallbackUrl ??
      (isCheckListItemLink(item)
        ? item.url
        : isCheckListItemTask(item)
          ? item.actionUrl
          : undefined);

    if (!url) return;

    if (!item.completed && isCheckListItemLink(item)) {
      mutateCheckListItemState({
        actionId: item.actionId,
        state: true,
      });
    }

    if (item.completed && !isCheckListItemLink(item)) return;

    const isInternalLink = url.startsWith('/') || url.startsWith('#') || url.startsWith('?');

    if (isInternalLink) {
      router.push(url, undefined, { shallow: true });
    } else {
      window.open(url, '_blank');
    }
  };

  return handleLinkTrigger;
};
