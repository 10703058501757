import useDebounce from '@/src/hooks/debounce';
import { onboardingCheckList } from '@/src/modules/onboarding/onboarding.config';
import { UserPreferences } from '@/src/modules/user/user.types';
import { useMemo } from 'react';

export const useChecklistItems = (preferences: UserPreferences | undefined) => {
  const rawItems = useMemo(() => {
    return onboardingCheckList.map((item) => ({
      ...item,
      completed: !!preferences?.frontend.onboardingChecklist?.[item.actionId],
    }));
  }, [preferences]);

  /**
   * This debounce is not for rerendering issues, this is a purposeful delay to make
   * newly ticked items feel more natural instead of instantly updating the UI.
   * E.g. pressing to watch a youtube video and it gets ticked before the youtube
   * video opens feels weird.
   * This is just for user feel.
   */
  const debouncedItems = useDebounce(preferences ? rawItems : undefined, 500);
  const items = debouncedItems ?? rawItems;

  return {
    items,
    isLoading: !preferences,
  };
};
