import { DashboardContentSection } from '@/src/core/shared/Dashboard/DashboardContent';
import { useGetStartedChecklist } from '@/src/modules/onboarding/hooks/useGetStartedChecklist';
import { Flex } from '@/src/modules/ui/components/Flex';
import { ListContainer } from '@/src/modules/ui/components/List/List';
import { framerAnimationFade } from '@/src/modules/ui/constants/framerAnimations';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { ChecklistHeader } from './ChecklistHeader';
import { ChecklistItem } from './ChecklistItem';
import { ChecklistSkeleton } from './ChecklistSkeleton';

const Section = styled(motion(DashboardContentSection))`
  margin-bottom: 30px;
`;

const StyledListContainer = styled(ListContainer)`
  gap: 0.125rem;
`;

const GetStartedChecklist: React.FC = () => {
  const { items, percentage, isLoading, onClickDontShowAgain, isChecklistHidden } =
    useGetStartedChecklist();

  if (isChecklistHidden) return null;

  if (isLoading) {
    return <ChecklistSkeleton />;
  }

  return (
    <Section {...framerAnimationFade}>
      <Flex gap="sectionsShort" direction="column">
        <ChecklistHeader percentage={percentage} onClickDontShowAgain={onClickDontShowAgain} />
        <StyledListContainer>
          {items?.map((item) => <ChecklistItem key={item.actionId} item={item} />)}
        </StyledListContainer>
      </Flex>
    </Section>
  );
};

export default GetStartedChecklist;
