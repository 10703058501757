import Dashboard from '@/src/core/shared/Dashboard';
import { NextPageWithAuth } from '@/src/types/page';
import Home from '@/src/views/Home/Home';

const HomePage: NextPageWithAuth = () => {
  return <Home />;
};

HomePage.authRequirements = { requiresAuth: true };
HomePage.WrappingComponent = Dashboard;

export default HomePage;
