import { Button } from '@/src/modules/ui/components/Button';
import styled from 'styled-components';

const ImportButton = styled(Button).attrs(({ children, ...props }) => {
  return {
    variant: 'primary-inverse',
    size: 'sm',
    children: <>{children || 'Import'}</>,
    ...props,
  };
})``;

export default ImportButton;
