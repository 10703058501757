import RightArrowFilled from '@/src/icons/RightArrowFilled';
import { LinkTouch } from '@/src/modules/ui/components/Link/LinkTouch';
import Section from '@/src/modules/ui/components/Section';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import ImportButton from './ImportButton';
import styles from './MigrationBanner.module.scss';

const MigrationBanner = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <Section ref={ref} className={clsx(className)} {...props}>
        <div className={styles.main_container}>
          <button className={styles.arrow_button}>
            <RightArrowFilled color="blue" />
          </button>

          <div className={styles.text}>
            <p>Migrate from another app</p>
            <p>Import data from another service to switch to Fabric and get started quickly</p>
          </div>

          <ImportButton as={LinkTouch} href="/import">
            Import
          </ImportButton>
        </div>
      </Section>
    );
  },
);

MigrationBanner.displayName = 'MigrationBanner';

export default MigrationBanner;
