import React from 'react';
import { IconProps } from './types';

const RightArrowFilled: React.FC<
  Omit<IconProps, 'color'> & {
    color: 'red' | 'blue';
  }
> = ({ color }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '9999px',
        background: color === 'blue' ? '#cccfff' : '#FFCCCC',
        width: '36px',
        height: '36px',
        transition: 'background 0.2s',
      }}
    >
      <svg
        width="28"
        height="16"
        viewBox="0 0 28 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-0.5"
          y="6"
          width="23"
          height="4"
          fill={color === 'blue' ? '#0010FF' : '#FF0000'}
          style={{
            transition: 'fill 0.2s',
          }}
        />
        <path
          d="M14.5 1.78956C14.5 1.00724 15.3578 0.527894 16.0241 0.937906L26.1161 7.14834C26.7506 7.53883 26.7506 8.46117 26.1161 8.85166L16.0241 15.0621C15.3578 15.4721 14.5 14.9928 14.5 14.2104V1.78956Z"
          fill={color === 'blue' ? '#0010FF' : '#FF0000'}
          style={{
            transition: 'fill 0.2s',
          }}
        />
      </svg>
    </div>
  );
};

export default RightArrowFilled;
