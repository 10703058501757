import ChevronRightThinIcon from '@/public/images/icons/ChevronRightThin.svg';
import PlusIcon from '@/public/images/icons/Plus.svg';
import { DashboardContentSection } from '@/src/core/shared/Dashboard/DashboardContent';
import { ConnectionsListActionsProvider } from '@/src/modules/connections/components/ConnectionsListActionsProvider';
import { useQueryResourceRootIntegrationList } from '@/src/modules/connections/queries/useQueryResourceRootIntegrationList';
import {
  HomeSection,
  HomeSectionTitle,
  ResourceItemsContainer,
} from '@/src/modules/home/components/HomeComponents';
import { ResourcePreviewCompactCard } from '@/src/modules/resources/components/ResourcePreviewCompactCard';
import { LinkTouch } from '@/src/modules/ui/components/Link/LinkTouch';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import Tooltip from '@/src/ui/Tooltip';

export const HomeIntegrationsSection = () => {
  const { integrationRoots } = useQueryResourceRootIntegrationList();

  return (
    <ConnectionsListActionsProvider>
      <HomeSection>
        <DashboardContentSection>
          <HomeSectionTitle as={LinkTouch} href="/connections">
            Data connections <ChevronRightThinIcon style={{ height: 12 }} />
          </HomeSectionTitle>
        </DashboardContentSection>
        <ScrollArea orientation="horizontal" scrollbarVariant="none">
          <DashboardContentSection>
            <ResourceItemsContainer>
              <ResourcePreviewCompactCard
                variant="primary-inverse"
                title="Add data connection"
                as={LinkTouch}
                href="/add-connection"
                icon={
                  <PlusIcon
                    style={{
                      width: 19,
                      height: 'auto',
                    }}
                  />
                }
              />
              {integrationRoots.map(({ integration }) => (
                <ResourcePreviewCompactCard
                  key={integration.id}
                  title={integration.viewConfig.displayName}
                  icon={
                    <Tooltip label={integration.viewConfig.tooltipText} placement="top" delay={300}>
                      <span>
                        <integration.viewConfig.Icon {...integration.viewConfig.IconProps} />
                      </span>
                    </Tooltip>
                  }
                  as={LinkTouch}
                  href={integration.viewConfig.navigateTo}
                />
              ))}
            </ResourceItemsContainer>
          </DashboardContentSection>
        </ScrollArea>
      </HomeSection>
    </ConnectionsListActionsProvider>
  );
};
