import CheckIcon from '@/public/images/icons/Checkmark.svg';
import { cssVar } from '@/src/modules/ui/theme/variables';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import React from 'react';
import styled from 'styled-components';

const IndeterminateSymbol = styled.div`
  width: 9px;
  height: 1.5px;
  background: white;
  border-radius: 1.5px;
`;

const CheckboxRoot = styled(CheckboxPrimitive.Root)`
  flex-shrink: 0;
  background-color: ${cssVar['color-bg-tertiary']};
  border: 1px solid ${cssVar['color-border-primary']};
  border-radius: ${cssVar['radius-input-sm']};

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  &[data-state='checked'],
  &[data-state='indeterminate'] {
    background-color: ${cssVar['color-app-primary']};
    border-color: ${cssVar['color-app-primary']};
    color: white;
  }
`;

const CheckboxIndicator = CheckboxPrimitive.Indicator;

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxRoot>,
  React.ComponentPropsWithoutRef<typeof CheckboxRoot>
>((props, ref) => (
  <CheckboxRoot {...props} ref={ref}>
    <CheckboxIndicator>
      {props.checked === 'indeterminate' ? (
        <IndeterminateSymbol />
      ) : (
        <CheckIcon
          style={{
            width: 9.5,
            height: 'auto',
          }}
        />
      )}
    </CheckboxIndicator>
  </CheckboxRoot>
));

Checkbox.displayName = 'Checkbox';

export { Checkbox };
