import { useAutoCheckItems } from '@/src/modules/onboarding/hooks/useAutoCheckItems';
import { useChecklistCompletion } from '@/src/modules/onboarding/hooks/useChecklistCompletion';
import { useIsChecklistHidden } from '@/src/modules/onboarding/hooks/useChecklistHidden';
import { useChecklistItems } from '@/src/modules/onboarding/hooks/useChecklistitems';
import { useMutationUserPreferencesUpdate } from '@/src/modules/user/mutations/useMutationUserPreferencesUpdate';
import { useQueryUserPreferences } from '@/src/modules/user/queries/useQueryUserPreferences';

export const useGetStartedChecklist = () => {
  const { data: preferences, isLoading: arePreferencesLoading } = useQueryUserPreferences({
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchInterval: 120 * 1000, // Refetch every 2 minutes
  });
  const isChecklistHidden = useIsChecklistHidden();

  const mutateUserPreferences = useMutationUserPreferencesUpdate();

  useAutoCheckItems(preferences, isChecklistHidden);

  const { items, isLoading: areItemsLoading } = useChecklistItems(preferences);
  const { percentage } = useChecklistCompletion(items);

  const onClickDontShowAgain = () => {
    mutateUserPreferences.mutate({
      data: {
        frontend: {
          ...preferences?.frontend,
          onboardingChecklistShow: false,
        },
      },
      showToast: false,
    });
  };

  return {
    items,
    percentage,
    isLoading: arePreferencesLoading || areItemsLoading,
    onClickDontShowAgain,
    isChecklistHidden,
  };
};
